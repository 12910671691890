import React, { useState, useContext } from "react"
import { Formik, Form } from "formik"

import Layout from "layout"
import EditForms from "./EditForms"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"

import { profileFormSchema, formInitialValues } from "./utils/profileFormSchema"
import { handleProfileEditSubmit } from "./services/profile"
import { getSignedInUser } from "../Auth/services/user"
import { AppContext } from "../../context/AppContext"
import { navigate } from "gatsby"

import DeleteAddressModal from "./Addresses/DeleteAddressModal"

const ProfileForm = (props) => {
  const { dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})

  let { pageContext, location } = props
  let { backPath, module } = pageContext

  let forceReset = location?.state?.forceReset

  let userData = {}
  if (module.name === "edit") userData = getSignedInUser()?.userData

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)

    handleProfileEditSubmit({
      values,
      resetForm,
      setMessage,
      setLoading,
      userData,
      dispatch,
      module: module.name,
    })
  }

  const handleDeleteAddress = ({ address, index }) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Delete Address",
        isCard: true,
        headerClass: `has-text-danger has-background-danger-light has-text-weight-bold is-size-5`,
        content: (
          <DeleteAddressModal
            address={address}
            index={index}
            handleDeleteCallback={() => {
              navigate("/profile")
            }}
          />
        ),
      },
    })
  }

  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={module.subtitle}
      isPrivate
      isPatient
      pageContext={pageContext}
    >
      <Container isCentered>
        {forceReset && (
          <Message color="warning">
            For your account security, we require you to change your password.
          </Message>
        )}
        <Formik
          initialValues={formInitialValues(
            module.name,
            userData,
            location?.state?.address
          )}
          validationSchema={profileFormSchema(module.name)}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <EditForms
                setFieldValue={setFieldValue}
                module={module.name}
                values={values}
              />
              {module.name === "edit-address" && !values?.address?.primary && (
                <button
                  type="button"
                  className="button is-danger mb-2"
                  onClick={() => {
                    handleDeleteAddress({
                      index: location?.state?.index,
                      address: location?.state?.address,
                    })
                  }}
                >
                  Delete this address
                </button>
              )}
              {message?.content && (
                <Message color={message?.type}>
                  {message?.content?.message || message?.content}
                </Message>
              )}
              <ActionButtons
                back={{ label: "Cancel", link: backPath }}
                submit={{
                  loading: loading,
                  label: module.cta,
                }}
                hasCaptcha={true}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default ProfileForm
